import Vue from "vue";
import VueRouter from "vue-router";
import useJwt from "@/auth/jwt/useJwt";
import { canNavigate } from "@/libs/acl/routeProtection";
import acl from "@/utils/acl";
import Menus from "@/navigation/vertical/index";
import constants from "@/utils/constants";
import store from "@/store";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: (to) =>
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        ({ name: "login" })
    },
    // {
    //   path: "/dashboard",
    //   name: "home",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Home.vue"),
    //   meta: {
    //     pageTitle: "Dashboard",
    //     resource: "widgets",
    //     action: constants.LABELS.DASH,
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Dashboard",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/dashboard",
      name: "dashboard",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Dashboard/Consumer/Home.vue"),
      meta: {
        pageTitle: "Dashboard",
        resource: "widgets",
        action: constants.LABELS.DASH,
        breadcrumb: [
          {
            text: "Breadcrumb_Dashboard",
            active: true
          }
        ]
      }
    },
    {
      path: "/profile",
      name: "profile",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Profile/ViewProfile.vue"),
      meta: {
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",
            active: true
          }
        ]
      }
    },
    {
      path: "/geofences",
      name: "geofences-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "geofences",
        action: constants.LABELS.READ,
        navActiveLink: "geofences",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/geofence/create",
      name: "geofence-create",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "geofences",
        action: constants.LABELS.ADD,
        navActiveLink: "geofences-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/geofence/:id/edit",
      name: "geofence-edit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "geofences",
        action: constants.LABELS.UPDATE,
        navActiveLink: "geofences-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/geofence/:id/view",
      name: "geofence-view",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "geofences",
        action: constants.LABELS.READ,
        navActiveLink: "geofences-list",
        pageTitle: "Geofences"
      }
    },

    {
      path: "/routes",
      name: "routes-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "routes",
        action: constants.LABELS.READ,
        navActiveLink: "routes",
        pageTitle: "routes"
      }
    },
    {
      path: "/route/create",
      name: "route-create",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "routes",
        action: constants.LABELS.ADD,
        navActiveLink: "routes-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/route/:id/edit",
      name: "route-edit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "routes",
        action: constants.LABELS.UPDATE,
        navActiveLink: "routes-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/route/:id/view",
      name: "route-view",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Geofence/index.vue"),
      meta: {
        resource: "routes",
        action: constants.LABELS.READ,
        navActiveLink: "routes-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/sim",
      name: "sim-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Sim/Sim.vue"),
      meta: {
        resource: "sims",
        action: constants.LABELS.READ,
        navActiveLink: "sim",
        pageTitle: "Sim Management",
        breadcrumb: [
          {
            text: "Sim List",
            active: true
          }
        ]
      }
    },

    {
      path: "/sim/create",
      name: "sim-create",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Sim/SimCreate.vue"),
      meta: {
        resource: "sims",
        action: constants.LABELS.CREATE,
        navActiveLink: "sim-list",
        pageTitle: "Sim Create",
        breadcrumb: [
          {
            text: "Sim List",
            active: true
          }
        ]
      }
    },
    {
      path: "/sim/:id/edit",
      name: "sim-edit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Sim/SimCreate.vue"),
      meta: {
        resource: "sims",
        action: constants.LABELS.UPDATE,
        navActiveLink: "sim-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/sim/:id/view",
      name: "sim-view",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Sim/SimCreate.vue"),
      meta: {
        resource: "sims",
        action: constants.LABELS.READ,
        navActiveLink: "sim-list",
        pageTitle: "Geofences"
      }
    },
    {
      path: "/sim-info/:id",
      name: "sim-info",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Sim/SimInfo.vue"),
      meta: {
        resource: "sims",
        action: constants.LABELS.READ,
        navActiveLink: "sim",
        pageTitle: "Sim Management",
        breadcrumb: [
          {
            text: "Sim Details",
            active: true
          }
        ]
      }
    },
    {
      path: "/devices",
      name: "devices-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/DeviceOnboarding/DevicesList.vue"),
      meta: {
        resource: "devices",
        action: constants.LABELS.READ,
        pageTitle: "Devices List",
        breadcrumb: [
          {
            text: "Breadcrumb_Devices",
            active: true
          }
        ]
      }
    },
    {
      path: "/repeaters",
      name: "repeaters",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/layouts/components/Repeaters/RepeatersModal.vue"),
      meta: {
        resource: "repeaters",
        action: constants.LABELS.MENU,
        breadcrumb: [
          {
            text: "Breadcrumb_Repeaters",
            active: true
          }
        ]
      }
    },

    {
      path: "/migrate",
      name: "migrate-data",
      beforeEnter: protectedRoute,
      component: () => import("@/views/MigrateData/MigrateData.vue"),
      meta: {
        resource: "migrations",
        action: constants.LABELS.UPDATE,
        breadcrumb: [
          {
            text: "Breadcrumb_Migrate",
            active: true
          }
        ]
      }
    },
    // {
    //   path: "/unit/:id/track",
    //   name: "track-unit",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/TrackUnitNew.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.READ,
    //     pageTitle: "Tracking",
    //     navActiveLink: "unit-map",
    //     breadcrumb: constants.UNIT_TRACKING_ROUTE_META_BREADCRUMB
    //   }
    // },
    {
      path: "/device/create",
      name: "add-new-device",
      beforeEnter: protectedRoute,
      component: () => import("@/views/DeviceOnboarding/DeviceCreation.vue"),
      meta: {
        resource: "devices",
        action: constants.LABELS.ADD,
        navActiveLink: "devices-list",
        pageTitle: "Add New Device",
        breadcrumb: [
          {
            text: "Breadcrumb_Devices",
            to: { name: "devices-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/device/create/:id",
      name: "add-new-device-id",
      beforeEnter: protectedRoute,
      component: () => import("@/views/DeviceOnboarding/DeviceCreation.vue"),
      meta: {
        resource: "devices",
        action: constants.LABELS.ADD,
        navActiveLink: "devices-list",
        pageTitle: "Add New Device",
        breadcrumb: [
          {
            text: "Breadcrumb_Devices",
            to: { name: "devices-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/alerts",
      name: "alert-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Alert/Alert.vue"),
      meta: {
        resource: "alert",
        action: constants.LABELS.READ,
        pageTitle: "Alert List",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            to: { name: "reference-data" }
          },
          {
            text: "Breadcrumb_Alerts",
            active: true
          }
        ]
      }
    },
    {
      path: "/alert/create",
      name: "alert-create",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Alert/AlertCreation.vue"),
      meta: {
        resource: "alert",
        action: constants.LABELS.ADD,
        navActiveLink: "alert-list",
        pageTitle: "Add New",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            to: { name: "reference-data" }
          },
          {
            text: "Breadcrumb_Alerts",
            to: { name: "alert-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/alert/:id/edit",
      name: "alert-edit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Alert/AlertCreation.vue"),
      meta: {
        resource: "alert",
        action: constants.LABELS.READ,
        navActiveLink: "alert-list",

        breadcrumb: [
          {
            text: "Breadcrumb_Alerts",
            to: { name: "alert-list" }
          }
        ]
      }
    },
    {
      path: "/alert/:id",
      name: "alert-view",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Alert/AlertCreation.vue"),
      meta: {
        resource: "alert",
        navActiveLink: "alert-list",
        action: constants.LABELS.READ,

        breadcrumb: [
          {
            text: "Breadcrumb_Alerts",
            to: { name: "alert-list" }
          }
        ]
      }
    },
    {
      path: "/device/:id/edit",
      name: "edit-device",
      beforeEnter: protectedRoute,
      component: () => import("@/views/DeviceOnboarding/DeviceCreation.vue"),
      meta: {
        resource: "devices",
        action: constants.LABELS.UPDATE,
        pageTitle: "Edit Device",
        navActiveLink: "devices-list",
        breadcrumb: [
          {
            text: "Breadcrumb_Devices",
            active: true
          }
        ]
      }
    },
    {
      path: "/device/:id",
      name: "view-device",
      beforeEnter: protectedRoute,
      component: () => import("@/views/DeviceOnboarding/DeviceCreation.vue"),
      meta: {
        resource: "devices",
        action: constants.LABELS.READ,
        navActiveLink: "devices-list",
        pageTitle: "View Device",
        breadcrumb: [
          {
            text: "Breadcrumb_Devices",
            active: true
          }
        ]
      }
    },
    {
      path: "/users",
      name: "user-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/UserOnboarding/UserList.vue"),
      meta: {
        resource: "user",
        action: constants.LABELS.READ,
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Breadcrumb_Users",
            active: true
          }
        ]
      }
    },

    {
      path: "/dealers",
      name: "dealer",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/Dealer.vue"),
      meta: {
        pageTitle: "Dealer",
        navActiveLink: "dealer",
        breadcrumb: constants.DEALER_ROUTE_META_BREADCRUMB,
        resource: "account",
        action: constants.LABELS.DEALER_MENU
      }
    },
    {
      path: "/sub-dealer/:account_id",
      name: "sub_dealer",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/views/ConsumerOnboarding/DealerSubAccount.vue"),
      meta: {
        pageTitle: "DealerSubAccount",
        navActiveLink: "dealer",
        breadcrumb: constants.DEALER_ROUTE_META_BREADCRUMB,
        resource: "account",
        action: constants.LABELS.READ
      }
    },
    {
      path: "/sub-consumer/:account_id",
      name: "sub_consumer",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/views/ConsumerOnboarding/DealerSubAccount.vue"),
      meta: {
        pageTitle: "DealerSubAccount",
        navActiveLink: "consumer",
        breadcrumb: constants.DEALER_ROUTE_META_BREADCRUMB,
        resource: "account_devices",
        action: constants.LABELS.CONSUMER_READ
      }
    },
    {
      path: "/dealer/create",
      name: "dealer-creation",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        resource: "account",
        navActiveLink: "dealer",
        action: constants.LABELS.ADD,
        pageTitle: "Dealer Creation",
        breadcrumb: [
          {
            text: "Breadcrumb_Dealer",
            to: { name: "dealer" }
          }
        ]
      }
    },
    {
      path: "/account/update",
      name: "account-update",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account",
        action: constants.LABELS.UPDATE,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/consumer-account/update",
      name: "consumer-account-update",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account_devices",
        action: constants.LABELS.UPDATE_CONSUMER,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/child/account/update",
      name: "account-update-details",
      beforeEnter: protectedRoute,
      // component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      component: () =>
        import("@/layouts/components/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account",
        action: constants.LABELS.UPDATE,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/child/sub-account/update",
      name: "sub-account-update-details",
      beforeEnter: protectedRoute,
      // component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      component: () =>
        import("@/layouts/components/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account",
        action: constants.LABELS.ACCOUNT_UPDATE,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/child/consumer-account/update",
      name: "consumer-account-update-details",
      beforeEnter: protectedRoute,
      // component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      component: () =>
        import("@/layouts/components/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account",
        action: constants.LABELS.UPDATE_CONSUMER,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/child/consumer-sub-account/update",
      name: "consumer-sub-account-update-details",
      beforeEnter: protectedRoute,
      // component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      component: () =>
        import("@/layouts/components/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        pageTitle: "Account Update",
        resource: "account",
        action: constants.LABELS.UPDATE_CONSUMER_CHILD_ACCOUNT,
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",

            active: true
          },
          {
            text: "Breadcrumb_Account Settings",

            active: true
          }
        ]
      }
    },
    {
      path: "/consumers",
      name: "consumer",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/Consumer.vue"),
      meta: {
        resource: "account_devices",
        navActiveLink: "consumer",
        action: constants.LABELS.CONSUMER_MENU,
        pageTitle: "Consumer",
        breadcrumb: constants.CONSUMER_ROUTE_META_BREADCRUMB
      }
    },
    {
      path: "/consumer/create",
      name: "consumer-creation",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ConsumerOnboarding/AccountDetails.vue"),
      meta: {
        resource: "account",
        action: constants.LABELS.CONSUMER_ADD,
        navActiveLink: "consumer",
        // pageTitle: "Consumer Creation",
        breadcrumb: [
          {
            text: "Breadcrumb_Consumer",
            to: { name: "consumer" }
          }
        ]
      }
    },
    {
      path: "/role/:id/edit",
      name: "edit-role",
      beforeEnter: protectedRoute,
      component: () => import("@/views/RolesManagement/EditRole.vue"),
      meta: {
        resource: "role",
        navActiveLink: "user-list",
        action: constants.LABELS.UPDATE,
        pageTitle: "Roles",
        breadcrumb: [
          {
            text: "Breadcrumb_Edit",
            active: true
          }
        ]
      }
    },
    {
      path: "/role/:id/view",
      name: "view-role",
      beforeEnter: protectedRoute,
      component: () => import("@/views/RolesManagement/EditRole.vue"),
      meta: {
        resource: "role",
        navActiveLink: "user-list",
        action: constants.LABELS.READ,
        pageTitle: "Roles",
        breadcrumb: [
          {
            text: "Breadcrumb_Roles",
            active: true
          }
        ]
      }
    },
    {
      path: "/roles-list",
      name: "roles-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/RolesManagement/RoleList.vue"),
      meta: {
        resource: "role",
        navActiveLink: "user-list",
        action: constants.LABELS.READ,
        pageTitle: "Roles",
        breadcrumb: [
          {
            text: "Breadcrumb_Pages",
            active: true
          },
          {
            text: "Breadcrumb_Roles",
            active: true
          },
          {
            text: "Breadcrumb_Roles List",
            active: true
          }
        ]
      }
    },
    {
      path: "/role/create",
      name: "create-role",
      beforeEnter: protectedRoute,
      component: () => import("@/views/RolesManagement/AddRole.vue"),
      meta: {
        resource: "role",
        navActiveLink: "user-list",
        action: constants.LABELS.ADD,
        pageTitle: "Roles",

        breadcrumb: [
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/update-profile",
      name: "update-profile",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Profile/UpdateProfile.vue"),
      meta: {
        pageTitle: "Update Profile",
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",
            active: false,
            to: {
              name: "profile"
            }
          },
          {
            text: "Breadcrumb_Update Profile",
            active: true
          }
        ]
      }
    },

    {
      path: "/unit-creation",
      name: "unit-creation",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitCreation.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.ADD,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            to: {
              name: "unit-map"
            }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },

    // {
    //   path: "/units/map",
    //   name: "unit-map",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitListView.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.READ,
    //     breadcrumb: [
    //       {
    //         text: "Units ",
    //         active: true
    //       },
    //       {
    //         text: "Map View",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/units/list",
      name: "unit-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitListView.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.READ,
        navActiveLink: "unit-list",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_List View",
            active: true
          }
        ]
      }
    },
    {
      path: "/units/map",
      name: "unit-map",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.READ,

        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Map View",
            active: true
          }
        ]
      }
    },

    {
      path: "/rules",
      name: "rules",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/Rules.vue"),
      meta: {
        pageTitle: "Unit",
        breadcrumb: [
          {
            text: "Breadcrumb_Device",
            active: true
          },
          {
            text: "Breadcrumb_Configure Events",
            active: false
          }
        ],
        resource: "units",
        action: constants.LABELS.ADD
      }
    },
    {
      path: "/rules-config",
      name: "rules-config",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/RuleConfig.vue"),
      meta: {
        pageTitle: "Unit",
        breadcrumb: [
          {
            text: "Breadcrumb_Units Name",
            active: false
          },
          {
            text: "Breadcrumb_Device Name",
            active: false
          },
          {
            text: "Breadcrumb_Configure Events",
            active: false
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ],
        resource: "units",
        action: constants.LABELS.ADD
      }
    },
    {
      path: "/notifications",
      name: "notification-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Notification/Notification.vue"),
      meta: {
        resource: "notification",
        action: constants.LABELS.READ,
        pageTitle: "Notification List",
        breadcrumb: [
          {
            text: "Breadcrumb_Notifications"
          }
        ]
      }
    },
    {
      path: "/notification/create",
      name: "notification-create",

      beforeEnter: protectedRoute,
      component: () => import("@/views/Notification/NotificationCreate.vue"),
      meta: {
        action: constants.LABELS.ADD,
        resource: "notification",
        navActiveLink: "notification-list",
        pageTitle: "Create New Notification",
        breadcrumb: [
          {
            text: "Breadcrumb_Notifications",
            to: { name: "notification-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/notification/:id/edit",
      name: "notification-edit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Notification/NotificationCreate.vue"),
      meta: {
        resource: "notification",
        action: constants.LABELS.UPDATE,
        navActiveLink: "notification-list",
        breadcrumb: [
          {
            text: "Breadcrumb_Notifications",
            to: { name: "notification-list" }
          }
        ]
      }
    },
    {
      path: "/notification/:id",
      name: "notification-view",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Notification/NotificationCreate.vue"),
      meta: {
        resource: "notification",
        action: constants.LABELS.READ,
        navActiveLink: "notification-list",

        breadcrumb: [
          {
            text: "Breadcrumb_Notifications",
            to: { name: "notification-list" }
          }
        ]
      }
    },
    {
      path: "/edit-event",
      name: "edit-event",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/EditRule.vue"),
      meta: {
        pageTitle: "Unit",
        breadcrumb: [
          {
            text: "Breadcrumb_Units Name",
            active: false
          },
          {
            text: "Breadcrumb_Device Name",
            active: false
          },
          {
            text: "Breadcrumb_Configure Events",
            active: false
          },
          {
            text: "Breadcrumb_Edit",
            active: true
          }
        ],
        resource: "units",
        action: constants.LABELS.ADD
      }
    },
    {
      path: "/event-list",
      name: "event-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/EventList.vue"),
      meta: {
        pageTitle: "Unit",
        breadcrumb: [
          {
            text: "Breadcrumb_Units Name",
            active: false
          },
          {
            text: "Breadcrumb_Device Name",
            active: false
          },
          {
            text: "Breadcrumb_Configure Events",
            active: false
          },
          {
            text: "Breadcrumb_Add New"
          }
        ]
      }
    },
    {
      path: "/unit/:id/edit",
      name: "edit-unit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/EditUnit.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.READ,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          }
        ]
      }
    },
    {
      path: "/unit/:id/view",
      name: "view-unit",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/EditUnit.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.READ,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          }
        ]
      }
    },
    {
      path: "/new-user-onboarding",
      name: "new-user-onboarding",
      component: () => import("@/views/UserOnboarding/NewUserOnboarding.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/welcome-user/:token",
      name: "welcome-user",
      component: () => import("@/views/UserOnboarding/WelcomeUser.vue"),
      meta: {
        layout: "full"
      }
    },

    {
      path: "/change-password",
      name: "change-password",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Profile/ChangePassword.vue"),
      meta: {
        pageTitle: "Settings",
        breadcrumb: [
          {
            text: "Breadcrumb_Change Password",
            active: true
          }
        ]
      }
    },
    {
      path: "/login",
      name: "login",
      beforeEnter(to, from, next) {
        if (checkToken()) {
          if (
            acl.checkAbility({
              action: constants.LABELS.MENU,
              subject: "units"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            next({
              name: "unit-map",
              query: { account_id: localStorage.getItem("ACCOUNT_ID") }
            });
          } else if (
            acl.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER"
          ) {
            next({
              name: "home"
            });
          } else if (
            acl.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account_devices"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            next({
              name: "dashboard"
            });
          } else {
            try {
              Menus.forEach((element) => {
                if (
                  acl.checkAbility({
                    action: element.action,
                    subject: element.resource
                  }) &&
                  element.route
                ) {
                  throw element;
                } else {
                  if (element && element.children && element.children.length) {
                    element.children.forEach((child) => {
                      if (
                        acl.checkAbility({
                          action: child.action,
                          subject: child.resource
                        })
                      ) {
                        throw child;
                      }
                    });
                  }
                }
              });
            } catch (e) {
              if (e.route) {
                next({ name: e.route });
              } else {
                // localStorage.setItem("TEMP_URL", "user-invitations");
                next({ name: "user-invitations" });

                // if (oldPermission && oldPermission !== newPermission) {
                //   this.$router.go();
                // } else {
                //   this.$router.push({ name: "user-invitations" });
                // }
              }
            }
          }
          // next("/user-invitations");
        } else next();
      },
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/ForgotPassword/ForgotPassword.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/forgot-password-II",
      name: "forgot-password-II",
      component: () => import("@/views/ForgotPassword/ForgotPassword-II.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/forgot-password-III",
      name: "forgot-password-III",
      component: () => import("@/views/ForgotPassword/ForgotPassword-III.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/set-new-password",
      name: "set-new-password",
      component: () => import("@/views/ForgotPassword/SetNewPassword.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full"
      }
    },

    {
      path: "/change-password",
      name: "settings",
      beforeEnter: protectedRoute,
      meta: {
        pageTitle: "Settings",
        breadcrumb: [
          {
            text: "Breadcrumb_Change Password",
            active: true
          }
        ]
      },
      component: () => import("@/views/Profile/ChangePassword.vue")
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import("@/views/error/Unauthorized.vue"),
      meta: {
        // layout: "full"
      }
    },
    {
      path: "*",
      redirect: "error-404"
    },
    {
      path: "/groups",
      name: "group-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Groups/Groups.vue"),
      meta: {
        resource: "label",
        action: constants.LABELS.READ,
        pageTitle: "Group List",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            to: { name: "reference-data" }
          },
          {
            text: "Breadcrumb_Groups",
            active: true
          }
        ]
      }
    },

    {
      path: "/groups/:id/edit",
      name: "edit-group",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Groups/ViewGroup.vue"),
      meta: {
        resource: "label",
        action: constants.LABELS.READ,
        navActiveLink: "group-list",
        pageTitle: "Edit Group",
        breadcrumb: [
          {
            text: "Breadcrumb_Groups",
            active: true
          }
        ]
      }
    },
    {
      path: "/groups/:id",
      name: "view-group",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Groups/ViewGroup.vue"),
      meta: {
        resource: "label",
        action: constants.LABELS.READ,
        navActiveLink: "group-list",
        pageTitle: "View Group",
        breadcrumb: [
          {
            text: "Breadcrumb_Groups",
            active: true
          }
        ]
      }
    },
    {
      path: "/operators",
      name: "operator-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Operators/Operators.vue"),
      meta: {
        resource: "operator",
        action: constants.LABELS.READ,
        pageTitle: "Operator List",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            to: { name: "reference-data" }
          },
          {
            text: "Breadcrumb_Operators",
            active: true
          }
        ]
      }
    },
    {
      path: "/operator/create",
      name: "create-operator",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Operators/CreateOperator.vue"),
      meta: {
        resource: "operator",
        action: constants.LABELS.ADD,
        navActiveLink: "operator-list",
        pageTitle: "Create",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            to: { name: "reference-data" }
          },
          {
            text: "Breadcrumb_Operators",
            to: { name: "operator-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/operator/:id/edit",
      name: "edit-operator",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Operators/CreateOperator.vue"),
      meta: {
        resource: "operator",
        navActiveLink: "operator-list",
        action: constants.LABELS.UPDATE,
        pageTitle: "Edit Operator",
        breadcrumb: [
          {
            text: "Breadcrumb_Operators",
            to: { name: "operator-list" }
          },
          {
            text: "Breadcrumb_Edit Operator",
            active: true
          }
        ]
      }
    },
    {
      path: "/operator/:id",
      name: "view-operator",
      beforeEnter: protectedRoute,

      component: () => import("@/views/Operators/CreateOperator.vue"),
      meta: {
        resource: "operator",
        action: constants.LABELS.READ,
        navActiveLink: "operator-list",
        pageTitle: "View Operator",
        breadcrumb: [
          {
            text: "Breadcrumb_Operators",
            to: { name: "operator-list" }
          },
          {
            text: "Breadcrumb_View Operator",
            active: true
          }
        ]
      }
    },
    {
      path: "/user-invitations",
      name: "user-invitations",
      beforeEnter: protectedRoute,
      meta: {
        pageTitle: "Invitations",
        breadcrumb: [
          {
            text: "Breadcrumb_Profile",
            active: true
          },
          {
            text: "Breadcrumb_Invitations",
            active: true
          }
        ]
      },
      component: () => import("@/views/UserInvitations/UserInvitations.vue")
    },
    {
      path: "/accounts",
      name: "accounts",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Account/Account.vue"),
      meta: {
        action: constants.LABELS.READ,
        pageTitle: "Account",
        breadcrumb: [
          {
            text: "Breadcrumb_Accounts",
            active: true
          }
        ]
      }
    },
    {
      path: "/reference-data",
      name: "reference-data",
      beforeEnter: protectedRoute,
      component: () => import("@/views/ReferenceData/ReferenceData.vue"),
      meta: {
        action: constants.LABELS.READ,
        navActiveLink: "reference-data",
        pageTitle: "Reference Data",
        breadcrumb: [
          {
            text: "Breadcrumb_Reference Data",
            active: true
          }
        ]
      }
    },
    {
      path: "/reports",
      name: "report-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Reports/ReportsList.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.READ,
        navActiveLink: "report-list",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            active: true
          }
        ]
      }
    },
    {
      path: "/report",
      name: "empty-report",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Reports/Reports.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.READ,
        navActiveLink: "report-list",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            active: true
          }
        ]
      }
    },
    {
      path: "/report/units",
      name: "select-unit",
      component: () => import("@/views/Reports/ReportHome.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.READ,
        navActiveLink: "report-list",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            to: { name: "report-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: false
          }
        ]
      }
    },
    {
      path: "/report/:id/edit",
      name: "edit-report",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Reports/ReportHome.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.UPDATE,
        navActiveLink: "report-list",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            active: true
          }
        ]
      }
    },
    {
      path: "/report/:id/view",
      name: "view-report",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Reports/ReportHome.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.READ,
        navActiveLink: "report-list",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            active: true
          }
        ]
      }
    },
    {
      path: "/report/create",
      name: "select-report",
      component: () => import("@/views/Reports/CreateReport.vue"),
      meta: {
        resource: "report",
        action: constants.LABELS.ADD,
        navActiveLink: "select-report",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Breadcrumb_Reports",
            active: true
          }
        ]
      }
    },
    {
      path: "/units/events",
      name: "unit-events",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_EVENTS,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Events",
            active: true
          }
        ]
      }
    },
    // {
    //   path: "/units/:id/events",
    //   name: "unit-events-details",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitTracking/index.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.SHOW_EVENTS,
    //     navActiveLink: "unit-map",
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Units",
    //         active: true
    //       },
    //       {
    //         text: "Breadcrumb_Events",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    // {
    //   path: "/units/:id/trips",
    //   name: "unit-trips-details",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitTracking/index.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.SHOW_TRIPS,
    //     navActiveLink: "unit-map",
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Units",
    //         active: true
    //       },
    //       {
    //         text: "Breadcrumb_Trips",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/units/trips",
      name: "unit-trips",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_TRIPS,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Trips",
            active: true
          }
        ]
      }
    },
    {
      path: "/units/services",
      name: "unit-services",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_SERVICES,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Services",
            active: true
          }
        ]
      }
    },
    // {
    //   path: "/units/:id/videos",
    //   name: "unit-videos-details",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitTracking/index.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.SHOW_VIDEOS,
    //     navActiveLink: "unit-map",
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Units",
    //         active: true
    //       },
    //       {
    //         text: "Breadcrumb_Videos",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/units/videos",
      name: "unit-videos",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_VIDEOS,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Videos",
            active: true
          }
        ]
      }
    },
    // {
    //   path: "/units/:id/activity",
    //   name: "unit-activity-details",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitTracking/index.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.SHOW_ACTIVITY,
    //     navActiveLink: "unit-map",
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Units",
    //         active: true
    //       },
    //       {
    //         text: "Breadcrumb_Activity",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/units/history",
      name: "unit-history",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_HISTORY,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_History",
            active: true
          }
        ]
      }
    },
    // {
    //   path: "/units/:id/unit-history",
    //   name: "unit-history-details",
    //   beforeEnter: protectedRoute,
    //   component: () => import("@/views/Unit/UnitTracking/index.vue"),
    //   meta: {
    //     resource: "units",
    //     action: constants.LABELS.SHOW_HISTORY,
    //     navActiveLink: "unit-map",
    //     breadcrumb: [
    //       {
    //         text: "Breadcrumb_Units",
    //         active: true
    //       },
    //       {
    //         text: "Breadcrumb_History",
    //         active: true
    //       }
    //     ]
    //   }
    // },
    {
      path: "/units/activity",
      name: "unit-activity",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Unit/UnitTracking/index.vue"),
      meta: {
        resource: "units",
        action: constants.LABELS.SHOW_ACTIVITY,
        navActiveLink: "unit-map",
        breadcrumb: [
          {
            text: "Breadcrumb_Units",
            active: true
          },
          {
            text: "Breadcrumb_Activity",
            active: true
          }
        ]
      }
    },
    {
      path: "/maintenance-reminders-list",
      name: "maintenance-reminder-list",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Maintenance/MaintenanceHome.vue"),
      meta: {
        resource: "service_reminders",
        action: constants.LABELS.READ,
        navActiveLink: "maintenance-reminder-list",
        pageTitle: "MaintenanceReminders",
        breadcrumb: constants.MAINTENANCE_REMINDERS_BREADCRUMB
      }
    },

    {
      path: "/maintenance-reminder/create",
      name: "maintenance-reminder-create",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/views/Maintenance/MaintenanceReminderCreate.vue"),
      meta: {
        resource: "service_reminders",
        action: constants.LABELS.ADD,
        navActiveLink: "maintenance-reminder-list",
        pageTitle: "Create",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          },
          {
            text: "Breadcrumb_Add New",
            active: true
          }
        ]
      }
    },
    {
      path: "/maintenance-reminder/:id/view",
      name: "maintenance-reminder-view",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/views/Maintenance/MaintenanceReminderCreate.vue"),
      meta: {
        resource: "service_reminders",
        navActiveLink: "maintenance-reminder-list",
        action: constants.LABELS.READ,
        pageTitle: "Edit Reminder",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          }
        ]
      }
    },
    {
      path: "/maintenance-history/:id",
      name: "Maintenance-History",
      beforeEnter: protectedRoute,
      component: () => import("@/views/Maintenance/MaintenanceHistory.vue"),
      meta: {
        resource: "service_reminders",
        navActiveLink: "maintenance-reminder-list",
        action: constants.LABELS.READ,
        pageTitle: "Edit Reminder",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          }
        ]
      }
    },
    {
      path: "/maintenance-reminder/:id/edit",
      name: "maintenance-reminder-edit",
      beforeEnter: protectedRoute,
      component: () =>
        import("@/views/Maintenance/MaintenanceReminderCreate.vue"),
      meta: {
        resource: "service_reminders",
        navActiveLink: "maintenance-reminder-list",
        action: constants.LABELS.UPDATE,
        pageTitle: "Edit Reminder",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          }
        ]
      }
    },
    {
      path: "/maintenance-reminder/:id/bill",
      name: "maintenance-reminder-add-bill",
      beforeEnter: protectedRoute,

      component: () => import("@/views/Maintenance/AddBill.vue"),
      meta: {
        resource: "service_reminders",
        action: constants.LABELS.READ,
        navActiveLink: "maintenance-reminder-list",
        pageTitle: "View Reminder",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          }
        ]
      }
    },
    {
      path: "/maintenance-reminder/:id/history",
      name: "maintenance-reminder-history-details",
      beforeEnter: protectedRoute,

      component: () =>
        import("@/views/Maintenance/MaintenanceHistoryDetails.vue"),
      meta: {
        resource: "service_reminders",
        action: constants.LABELS.READ,
        navActiveLink: "maintenance-reminder-list",
        pageTitle: "View Reminder",
        breadcrumb: [
          {
            text: "Breadcrumb_MaintenanceReminders",
            to: { name: "maintenance-reminder-list" }
          }
        ]
      }
    }
  ]
});

async function protectedRoute(to, from, next) {
  if (checkToken()) {
    let lastRoute = localStorage.getItem("LAST_ROUTE");
    if (lastRoute) {
      localStorage.removeItem("LAST_ROUTE");
      next(lastRoute);
    } else {
      next();
    }
  } else next("/");
}
function checkToken() {
  let isUserLoggedIn = localStorage.getItem(
    useJwt.jwtConfig.storageTokenKeyName
  );
  return !!isUserLoggedIn;
}
// ? For ACL
// Remove beforeEach hook if you are not using acl
router.beforeEach((to, from, next) => {
  let isAuthorized = canNavigate(to);

  if (isAuthorized && to.name !== "error-404") {
    store.commit("authorized/SET_AUTH_PERMISSION", true);
    store.commit("authorized/SET_LAST_ROUTE", null);
    next();
  } else {
    //   next({ name: "unauthorized" });
    store.commit("authorized/SET_AUTH_PERMISSION", false);
    store.commit("authorized/SET_LAST_ROUTE", to.name);
    localStorage.setItem("LAST_ROUTE", to.fullPath);
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
